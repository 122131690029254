import React from "react";
import "../styles.css";
import XX from "../img/x.png";
class Resume extends React.Component {
  render() {
    return (
      <div className="popupWrapper" onClick={this.props.closePopup}>
        <div className="popup">
          <div className="popupinner">
            <img
              onClick={this.props.closePopup}
              src={XX}
              style={{
                width: "2em",
                position: "absolute",
                right: "0.5em",
                top: "0.5em",
                cursor: "pointer",
                opacity: "0.1"
              }}
              alt="Close"
            />

            <h1>Vlad Svitlov</h1>
            <h4>1540 Carolina Dr. Vandalia, OH 45377 | 937-825-6627 </h4>

            <iframe
              title="Vlad Svitlov"
              className="doc"
              src="https://docs.google.com/document/d/e/2PACX-1vSUUbQCW0fRM4dIhsYcpvjyfEuDwpHLW83q2Q-19T28JZ7LSsTAl71tfrAOgo1F00nbvCLQ5sxeUF9O/pub?embedded=true"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Resume;
