import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import Header from "./comp/header";
import Info from "./comp/info";
import Portfolio from "./comp/portfolio";
import Contact from "./comp/contact";
import Footer from "./comp/footer";

import "./styles.css";
import "./mobile.css";
function App() {
  return (
    <div className="App">
      <Header />
      <Info />
      <Portfolio />
      <Contact />
      <Footer />
    </div>
  );
}

$(document).ready(function() {
  document.querySelector("#port").addEventListener("click", function() {
    document.querySelector("#portfolio").scrollIntoView({ behavior: "smooth" });
  });

  document.querySelector("#cont").addEventListener("click", function() {
    document.querySelector("#contact").scrollIntoView({ behavior: "smooth" });
  });

  document
    .querySelector("#diamondFooter")
    .addEventListener("click", function() {
      document.querySelector("#Svitlov").scrollIntoView({ behavior: "smooth" });
    });
});

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
