import React from "react";
import gifl from "../../img/cs1.gif";
import gifr from "../../img/cs2.gif";

function Csharp() {
  return (
    <div>
      <grid-container id="cSharpWrapper">
        <grid-item className="wrapper">
          <div className="containerC">
            <div className="item content-1">
              <div
                className="brickC"
                style={{
                  backgroundImage: `url(${gifl})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "101% 100%",
                  backgroundPosition: "center"
                }}
              />
            </div>
            <div className="item content-2">
              <div
                className="brickC"
                style={{
                  backgroundImage: `url(${gifr})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "101% 100%",
                  backgroundPosition: "center"
                }}
              />
            </div>
          </div>
        </grid-item>
        <grid-item>
          <h3>
            OpenGL program that targets and maps sensitive dots of any volume.
          </h3>
          The dots are figured and projected on a graph that saves the shape of
          the figure. The program allows user to get a precise visual of the dot
          structure of any figure. The program has four horizontal bars that can
          be used to:
          <ul
            style={{
              listStyle: "none",
              fontStyle: "italic",
              padding: "0"
            }}
          >
            <li>Rotate over Z-axis</li>
            <li>Rotate over Y-axis</li>
            <li>Rotate over X-axis</li>
            <li>Zoom in and out</li>
          </ul>
        </grid-item>
      </grid-container>
    </div>
  );
}

export default Csharp;
