import React, { Component, Fragment } from "react";
import { Button, TextField, MuiThemeProvider } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
const SubmitButton = styled(Button)({
  background: "linear-gradient(45deg, #e87168 55%, #f8bd83 90%)",
  border: 0,
  borderRadius: 3,
  color: "white",
  height: 48,
  padding: "0 30px"
});

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submit: true,
      firstName: "",
      email: "",
      message: "",
      response: "",
      reply: "",
      color: "#56b870",
      inputColor: ""
    };
  }
  // componentDidMount() {
  //   setTimeout(() => {
  //     console.log("");
  //   }, 1000);
  // }

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  toggleSubmit() {
    const { firstName, email, message } = this.state;
    const info = [firstName, email, message];
    //|| email.value || message.value
    firstName === ""
      ? this.setState({
          response: "Please, enter your name!",
          color: "#e34d4d",
          reply: ""
        })
      : email === ""
      ? this.setState({
          response: "Please, enter your E-mail!",
          color: "#e34d4d",
          reply: ""
        })
      : message === ""
      ? this.setState({
          response: "Please, enter your Message!",
          color: "#e34d4d",
          reply: ""
        })
      : console.log(info);

    this.setState({ submit: !this.state.submit });
    setTimeout(() => {
      this.setState({ submit: !this.state.submit });
      this.setState({
        firstName: "",
        email: "",
        message: "",
        color: "#5ad156",
        response: "Thank you for contacting me!",
        reply: "I will reply to your message shortly!"
      });
    }, 3000);
  }
  // componentDidUpdate() {
  //   console.log(this.handleChange());
  // }
  render() {
    return (
      <Fragment>
        {this.state.submit ? (
          <MuiThemeProvider>
            <p style={{ fontSize: "1.4em", marginTop: "3em" }}>Contact me:</p>
            <p style={{ fontSize: ".8em", color: "#aaa" }}>
              vladsvetlov@yahoo.com
            </p>
            <br />
            <TextField
              className="textField"
              InputLabelProps={{
                style: { color: this.state.inputColor }
              }}
              label="Name"
              inputprops=""
              variant="outlined"
              onChange={this.handleChange("firstName")}
            />
            <br />
            <br />
            <TextField
              className="textField"
              label="Email"
              variant="outlined"
              onChange={this.handleChange("email")}
            />
            <br />
            <br />
            <TextField
              multiline={true}
              className="textField"
              rows={5}
              label="Message"
              variant="outlined"
              onChange={this.handleChange("message")}
            />
            <br />
            <br />

            <SubmitButton
              onClick={this.toggleSubmit.bind(this)}
              disabled={this.state.disabled}
            >
              <p style={{ paddingLeft: "3em", paddingRight: "3em" }}>Submit</p>
            </SubmitButton>
          </MuiThemeProvider>
        ) : (
          <MuiThemeProvider>
            <p
              style={{
                fontSize: "1.4em",
                marginTop: "13em",
                color: this.state.color
              }}
            >
              {this.state.response}
            </p>
            <p
              style={{
                fontSize: ".8em",
                color: "#aaa"
              }}
            >
              {this.state.reply}
            </p>
          </MuiThemeProvider>
        )}
      </Fragment>
    );
  }
}
export default Form;
