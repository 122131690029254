import React from "react";
import csharp from "../img/c#.png";
import java from "../img/java.png";
import python from "../img/python.png";
import cSharp from "../img/csharp.mp4";
import Java from "../img/java.mp4";
function Info() {
  return (
    <div className="infoBg">
      <p id="hello">Hello! I am Vlad Svitlov</p>
      <grid-container>
        <grid-item>
          <div className="infoCircle" />

          <video
            className="vid"
            playsInline
            autoPlay
            muted
            loop
            src={cSharp}
            type="video/mp4"
          />

          <p>Great C# developer with many assignments completed.</p>
        </grid-item>
        <grid-item>
          <div className="infoCircle" />
          <video
            className="vid"
            playsInline
            autoPlay
            muted
            loop
            src={Java}
            type="video/mp4"
          />
          <p>
            Java developer with a significant experience in mobile development.
          </p>
        </grid-item>
      </grid-container>
      <grid-container id="infoInfo">
        <grid-item>
          <img alt="C#" src={csharp} />
        </grid-item>
        <grid-item>
          <img alt="Java" src={java} />
        </grid-item>
        <grid-item>
          <img alt="Python" src={python} />
        </grid-item>
        <grid-item>Over 4 years of C# experience</grid-item>
        <grid-item>Highly proficient in Java \ Eclipse IDE</grid-item>
        <grid-item>Created multiple projects using Python</grid-item>
      </grid-container>
    </div>
  );
}

export default Info;
