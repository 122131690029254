import React, { Component } from "react";
import download from "../img/download.png";
import { Button, MuiThemeProvider } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Resume from "./resume";
import Form from "./contact/form";

const ResumeButton = styled(Button)({
  background: "linear-gradient(45deg, #d9af80 35%, #e8a255 90%)",
  border: 0,
  borderRadius: 3,
  color: "white",
  height: 48,
  padding: "0 30px"
});

export class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      disabled: false,
      showResume: false,
      resumeButton: false
    };
  }
  toggleResume() {
    this.setState({
      showResume: !this.state.showResume
    });
  }

  render() {
    return (
      <div className="contactBg">
        <div>
          {this.state.showResume ? (
            <Resume
              text='Click "X" to hide popup'
              closePopup={this.toggleResume.bind(this)}
            />
          ) : null}
        </div>

        <MuiThemeProvider>
          <ResumeButton
            onMouseOut={() => this.setState({ resumeButton: true })}
            onMouseOver={() => this.setState({ resumeButton: false })}
            style={{
              marginTop: "7em"
            }}
            onClick={this.toggleResume.bind(this)}
          >
            <img
              id="contact"
              src={download}
              style={{
                transitionDuration: "0.2s",
                transform: ` scale(0.4) ${
                  this.state.resumeButton ? "rotate(-90deg)" : "rotate(-0deg)"
                }`,
                paddingLeft: ".5em"
              }}
              alt="Download My Resume"
            />{" "}
            <p style={{ paddingRight: "1.2em" }}>My Resume</p>
          </ResumeButton>
        </MuiThemeProvider>
        <Form />
      </div>
    );
  }
}

export default Contact;
