import React, { Component, Fragment } from "react";
import diamond from "../img/diamond.png";
import logo from "../img/logo.png";

class Header extends Component {
  constructor() {
    super();
    this.state = { hoveredl: false, hoveredr: false };
  }

  render() {
    return (
      <Fragment>
        <div id="Svitlov">
          <img alt="logo" src={logo} />
        </div>
        <div className="topBg">
          <grid-container>
            <grid-item>
              <p
              id="port"
                onMouseOut={() => {
                  this.setState({ hoveredl: false });
                }}
                onMouseOver={() => {
                  this.setState({ hoveredl: true });
                }}
                style={{
                  position: "relative",
                  transitionDuration: "0.2s",
                  color:"white",
                  zIndex: "999",
                  cursor: "pointer",
                  transform: `${
                    this.state.hoveredl ? "scale(1.2)" : "scale(1)"
                  }`
                }}
              >
                  Portfolio
              </p>
            </grid-item>
            <grid-item>
              <p
                id="cont"
                onMouseOut={() => {
                  this.setState({ hoveredr: false });
                }}
                onMouseOver={() => {
                  this.setState({ hoveredr: true });
                }}
                style={{
                  position: "relative",
                  transitionDuration: "0.2s",
                  zIndex: "999",
                  color:"white",
                  cursor: "pointer",
                  transform: `${
                    this.state.hoveredr ? "scale(1.2)" : "scale(1)"
                  }`
                }}
              >
                  Contact
              </p>
            </grid-item>
          </grid-container>
          <div className="topCircle" />
          <div className="topCircle bigger" />
          <div className="topTriangle" />
          <img alt="diamond" id="diamond" src={diamond} />
          <div className="ovalWrap">
            <div className="topOval" />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Header;
