import React, { Component, Fragment } from "react";
import diamond from "../img/diamondBig.png";
import Csharp from "./projects/csharp";
import Java from "./projects/java";
import Python from "./projects/python";
import Other from "./projects/other";
class Portfolio extends Component {
  constructor() {
    super();
    this.state = {
      item: 0,
      hr: 0
    };
  }

  renderSlides(item) {
    switch (item) {
      default:
        return <h1>Please enable Javascript!</h1>;
      case 0:
        return <Csharp />;
      case 100:
        return <Java />;
      case 200:
        return <Python />;
      case 300:
        return <Other />;
    }
  }

  render() {
    return (
      <Fragment>
        <p id="myProjects">Some of my Projects:</p>
        <div id="portfolio" className="menuBg">
          <grid-container>
            <grid-item
              onClick={() => {
                this.setState({ item: 0, hr: 0 });
              }}
            >
              <div
                id="menuBg"
                style={{ transform: `translateX(${this.state.item}%)` }}
              />
              <div id="menuTr" />
              C#
            </grid-item>
            <grid-item
              onClick={() => {
                this.setState({ item: 100, hr: 20 });
              }}
            >
              <div id="menuTr" />
              JAVA
            </grid-item>
            <grid-item
              onClick={() => {
                this.setState({ item: 200, hr: 40 });
              }}
            >
              <div id="menuTr" />
              PYTHON
            </grid-item>
            <grid-item
              onClick={() => {
                this.setState({ item: 300, hr: 60 });
              }}
            >
              <div id="menuTr" />
              OTHER
            </grid-item>
          </grid-container>

          <hr style={{ transform: `translateX(${this.state.hr}%)` }} />
        </div>

        <div className="portfolioBg">
          <div className="portfolioContainer">
            {this.renderSlides(this.state.item)}
          </div>

          <div className="portfolioBack" />
          <div className="bigDiamond">
            <img alt="diamond" src={diamond} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Portfolio;
