import React from "react";
import javaGif from "../../img/java.gif";

function Csharp() {
  return (
    <div>
      <grid-container id="cSharpWrapper">
        <grid-item className="wrapper">
          <div className="containerJ">
            <div
              className="brick"
              style={{
                backgroundImage: `url(${javaGif})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "101% 100%",
                backgroundPosition: "center"
              }}
            />
          </div>
        </grid-item>
        <grid-item>
          <br />
          <h3>Java Kettle program</h3>
          Java program that allows controlling the power and temperature of the
          kettle. Additionaly ,it includes the timer, and calculates and
          displays the average temperature values.
        </grid-item>
      </grid-container>
    </div>
  );
}

export default Csharp;
