import React from "react";
import diamond from "../img/diamond.png";
import inn from "../img/in.png";
import git from "../img/git.png";
import cod from "../img/cod.png";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <div className="Footer">
      <hr className="hr l" />
      <hr className="hr r" />
      <img alt="diamond" id="diamondFooter" src={diamond} />
      <div className="bottomTriangle"> </div>
      <grid-container>
        <grid-container id="mediaIcons">
          <grid-item>
            <a
              href="https://github.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="git" src={git} />
            </a>
          </grid-item>
          <grid-item>
            <a
              href="https://www.linkedin.com/in/vladyslav-svitlov-259031138/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="inn" src={inn} />
            </a>
          </grid-item>

          <grid-item>
            <a
              href="https://codesandbox.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <img alt="cod" src={cod} />
            </a>
          </grid-item>
        </grid-container>
        <grid-container id="svitlov">
          <grid-item>Vlad Svitlov, {year}</grid-item>
        </grid-container>
      </grid-container>

      <a href="https://xzo.io" target="_blank" rel="noopener noreferrer">
        Designed by <span style={{ color: "red" }}>Mark Alow</span>
      </a>
    </div>
  );
}

export default Footer;
